import axios from 'axios';

const API = 'https://admin.video.shdr.online/api';
const BEARER = 'Bearer';
const AUTH_TOKEN = 'authToken';

const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

const axiosInstance = axios.create({
  baseURL: API,
  timeout: 10000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const authToken = getToken();
    config.headers = {
      Authorization: `${BEARER} ${authToken}`,
      Accept: 'application/json',
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('axios error: ', error);
    throw error;
  }
);

export const fetchUserData = async (params) => {
  return axiosInstance({
    url: '/users/me',
    params,
  }).then((response) => {
    return response.data;
  });
};
