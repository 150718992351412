import React from 'react';
import { Slide } from 'react-reveal';
import { useTranslation } from 'react-i18next';

export default function Contacts({ data }) {
  const { t } = useTranslation('main');

  const phone = data.phone;
  const email = data.email;

  return (
    <section id="contact">
      <div className="row">
        <Slide right duration={1000}>
          <aside className="four columns footer-widgets">
            <div className="row">
              <div className="columns contact-details">
                <h2>{t('contactDetails')}</h2>
                <p className="address">
                  <span>{t('contactTitle')}</span>
                  <br />
                  <span> {t('state')}</span>
                  <br />
                  <span>{phone}</span>
                  <br />
                  <span>{email}</span>
                </p>
              </div>
              <div className="columns download"></div>
            </div>
          </aside>
        </Slide>
      </div>
    </section>
  );
}
