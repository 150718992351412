import React from 'react';
import ParticlesBg from 'particles-bg';
import Fade from 'react-reveal';
import { useTranslation } from 'react-i18next';
import LangSwitcher from './LangSwitcher';

export default function Header({ data }) {
  const { loginUrl } = data;

  const { t } = useTranslation('main');

  return (
    <header id="home">
      <ParticlesBg type="circle" bg={true} />

      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
          Show navigation
        </a>
        <a className="mobile-btn" href="#home" title="Hide navigation">
          Hide navigation
        </a>

        <ul id="nav" className="nav">
          <li className="current">
            <a className="smoothscroll" href="#home">
              {t('menu.home')}
            </a>
          </li>

          <li>
            <a className="smoothscroll" href="#about">
              {t('menu.about')}
            </a>
          </li>

          <li>
            <a className="smoothscroll" href="#contact">
              {t('menu.contact')}
            </a>
          </li>

          <LangSwitcher />
        </ul>
      </nav>

      <div className="row banner">
        <div className="banner-text">
          <Fade bottom>
            <h1 className="responsive-headline">{t('name')}</h1>
          </Fade>
          <Fade bottom duration={1200}>
            <h3>{t('description')}</h3>
          </Fade>
          <hr />
          <Fade bottom duration={2000}>
            <ul className="social">
              <a href={loginUrl} className="button btn project-btn">
                <i className="fa fa-book"></i>
                {t('login')}
              </a>
            </ul>
          </Fade>
        </div>
      </div>

      <p className="scrolldown">
        <a className="smoothscroll" href="#about">
          <i className="icon-down-circle"></i>
        </a>
      </p>
    </header>
  );
}
