import React from 'react';
import Fade from 'react-reveal';
import { useTranslation } from 'react-i18next';

export default function About({ data }) {
  const { t } = useTranslation('main');

  const { site, logo } = data;
  const logoPic = 'images/' + logo;

  const aboutTitle = t('about.title');
  const about = t('about.sections', { returnObjects: true });

  const shdrDescription = about.map((item, index) => {
    return <p key={index} dangerouslySetInnerHTML={{ __html: item.text }} />;
  });

  return (
    <section id="about">
      <Fade duration={1000}>
        <div className="row">
          <div className="three columns">
            <a href={site}>
              <img className="profile-pic" src={logoPic} alt={t('name')} />
            </a>
          </div>
          <div className="nine columns main-col">
            <h2>{aboutTitle}</h2>

            {shdrDescription}
          </div>
        </div>
      </Fade>
    </section>
  );
}
