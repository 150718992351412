import React, { Suspense, useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Contacts from './Components/Contacts';

export default function App() {
  const [configData, setConfigData] = useState({});

  useEffect(() => {
    const getData = () => {
      return axios({
        url: './configData.json?a=' + Math.random(),
      }).then((res) => {
        setConfigData(res.data);
      });
    };
    getData();
  }, []);

  return (
    <Suspense fallback="loading">
      {configData.main ? (
        <div className="App">
          <Header data={configData.main} />
          <About data={configData.main} />
          <Contacts data={configData.main} />
          <Footer data={configData.main} />
        </div>
      ) : (
        <></>
      )}
    </Suspense>
  );
}
