import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import './index.css';
import App from './App';
import { fetchUserData } from './api';

import en_main from './translations/en/main.json';
import ru_main from './translations/ru/main.json';

fetchUserData()
  .then((data) => {
    if (window.location.pathname === '/') {
      window.location.href = '/admin/filesystem';
    }
  })
  .catch((error) => {
    if (error.response.status === 401) {
      i18next.init({
        interpolation: { escapeValue: false }, // React already does escaping
        lng: 'en',
        resources: {
          en: {
            main: en_main,
          },
          ru: {
            main: ru_main,
          },
        },
      });

      const root = ReactDOM.createRoot(document.getElementById('root'));

      root.render(
        <React.StrictMode>
          <I18nextProvider i18n={i18next}>
            <App />
          </I18nextProvider>
        </React.StrictMode>
      );
    } else {
      document.write('Something went wrong');
    }
  });
