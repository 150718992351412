import { useTranslation } from 'react-i18next';

export default function LangSwitcher() {
  const { i18n } = useTranslation();

  const switchTo = (lang) => () => {
    i18n.changeLanguage(lang);
  };
  return (
    <>
      <span className="smoothscroll pointer" onClick={switchTo('en')}>
        En
      </span>
      |
      <span className="smoothscroll pointer" onClick={switchTo('ru')}>
        Ru
      </span>
    </>
  );
}
